import React from 'react';
import { useRef } from 'react';
import './Join.css';
import emailjs from '@emailjs/browser';

const Join = () => {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_uxlcucq', 'template_0caupfr', form.current, 'c_vfydtBbyOvDKllD')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="Join" id="oin-us">
      <div className="left-j">
        <hr />
        <div>
          <span className='stroke-text'>PRONTO PARA</span>
          <span>MOLDAR</span>
        </div>
        <div>
          <span>O SEU CORPO</span>
          <span className='stroke-text'>CONNOSCO?</span>
        </div>
      </div>
      <div className="right-j">
        <form ref={form} className="email-container" onSubmit={sendEmail}>
          <input type="email" name="user_email" placeholder="Insira o seu e-mail"/>
          <button className="btn btn-j">Inscreva-se Agora</button>
        </form>
      </div>
    </div>
  )
}

export default Join